export let texts = [
    {
        "id": "figma_data_binding",
        "name": "Data Binding",

        "elements": [     
            {
                "type": "Paragraph",
                "content": `
                   The Luisa component is contained in a VUE component, in our example, the Home.vue component. This component will be called
                   the <b>parent</b> in the following sections. Luisa allows
                   you to exchange data with the parent component, for example to show data that was loaded from
                   a server, or to store user input. 
                `
            },
            {
                "type": "Image",
                "src": 'DataBinding.png',
                "content": `Data is exchanges with the viewModel`
            },
            {
                "type": "Paragraph",
                "content": `
                   Data is exchanged between the parent and the Luisa component happens through the so-called <b>viewModel</b>. The viewModel is
                   a simple JavaScript object that is part of the <i>data</i> property of the parent component to allow reactiveness. When the parent 
                   changes the viewModel, Luisa will update. If the user enters data in Luisa, the viewModel will be updated as well, and the parent can access
                   the user input.
                `
            },
            {
                "type" : "CodePreview",
                "language": "vue",
                "caption": "The viewModel is part of the data property",
                "content": `
<template>
    <Luisa :design="design" :config="config" v-model="viewModel"/>
</template>

<script>
import app from './app.json'
export default {
    name: 'Home',
    data: function() {
        return {
            design: app
            viewModel: {
                contact: {
                    email: '',
                    status: 'Fill out the form',
                    message: ''
                }
            },
            config: {
            }
        }
    }
}
</script>
                `
            },    
            {
                "type": "Paragraph",
                "content": `
                   In the code snippet above you can see that the viewModel is defined in the parent component (Home.vue) and it is passed
                   through the <b>v-model</b> property to the Luisa component. In this example, the viewModel has a child 
                   object for a contact form.
                `
            }, 
            {
                "type": "Paragraph",
                "content": `
                   The question is now, how Luisa knows where to read data from and where to write data to? For instance if you want to show the 
                   value of the  <b>status</b> field (<i>Fill out the form</i>) in a text element.  This is where 
                   the Figma-Low-Code plugin comes into play. For the given element you can define the <b>data-binding</b>. The
                   data-binding works similarly to the way VUE addresses data. For the <b>status</b> variable, the binding value would be
                   "<span class="LuisaLowlight">contact.status</span>".
                `
            },
            {
                "type": "Image",
                "size": 'xl',
                "src": 'Plugin_Options.png',
                "description": `
                    <ol>
                        <li>
                            Launch the Figma-Low-Code plugin and select the desired element.
                        </li>  
                        <li>
                            Change to the <b>Options</b> tab.
                        </li> 
                        <li>
                             Enter in the <b>Data Binding</b> field the correct bindign value. The <b>contact</b> vaiable would be 
                            addressed as "<span class="LuisaLowlight">contact.status</span>"
                        </li>
                        <li>
                            When the viewModel is updated, e.g. in the parent, the text element will be updated.
                        </li>
                    </ol>               
                `,
                "content": `Showing data in a text element.`
            },           
            {
                "type": "Headline",
                "content": `User Input`,
                'id': 'low_code_data_binding.input'
            },
            {
                "type": "Paragraph",
                "content": `
                    To collect user input, Luisa needs to know which elements should be rendered as an input field. You can set the input type 
                    also with the Figma-Low-Code plugin. Once the type is set, you can define the data binding. 
                    <br>
                    In  our contact example, the user can enter her email, and the data should be saved in the <b>contact</b> object in the vieModel.  
                    To enable this, you need set the data binding to "<span class="LuisaLowlight">contact.email</span>" for the input element.
                `
            },
            {
                "type": "Image",
                "size": 'xl',
                "src": 'Plugin_Type.png',
                "description": `
                    <ol>
                        <li>
                            Select the desired elment in the Figma-Low-Code plugin.
                        </li>          
                        <li>
                            Select the correct type. For a simple text box choose <b>Input Field</b>
                        </li>
                        <li>
                            Enter in the <b>Data Binding</b> field the correct binding value. The <b>email</b> variable would be 
                            addressed as "<span class="LuisaLowlight">contact.email</span>"
                        </li>
                        <li>
                            When the user enters data, the viewModel will be updated
                        </li>
                    </ol>
                `,
                "content": `Reading user input with a text field`
            },
            {
                "type": "Headline",
                "content": `Example`,
                'id': 'low_code_data_binding.example'
            },
            {
                "type": "Paragraph",
                "content": `
                    Let's have a look how the data binding would look like for a simple contact form. The idea is that the users
                    can enter their email and a message. In addition, they get feedback about the status of the form, e.g. if they
                    should enter data, or if the form was sent. 
                `
            },
            {
                "type": "Paragraph",
                "content": `
                    To build the input fields, we create a frame. This will be the border. Afterward, we place a 
                    text element inside and set the type to <b>Input Field</b> in the plugin. We could also turn the 
                    frame into an input, however, this might lead to issues with padding. If we place a text element inside,
                    we can control the padding better. In addition, the text in the element will be used as a <b>placeholder</b>.
                `
            },
            {
                "type": "Image",
                "size": 'xs',
                "src": 'Example_Contact.png',
                "description": `
                    <b>Tip</b>: Build input elements by placing a text element inside a frame. Turn the text into a <b>Input Field</b>,
                   to control padding and the placeholder value.
                `,
                "content": `The Figma design of the contact form`
            },
            {
                "type": "Paragraph",
                "content": `
                    In the example above, the first element (<i>How can we reach you?</i>) will be bound to "<span class="LuisaLowlight">contact.email</span>". 
                    The second field (<i>Share your thoughts.</i>) will be set as <b>Text Area</b>  and will be bound to
                     "<span class="LuisaLowlight">contact.message</span>". Last we will bind the status text 
                     element (<i>Success</i>) to "<span class="LuisaLowlight">contact.status</span>". As the viewModel defines already a 
                     value for the "status" variable, it will show "<i>Fill out the form</i>" when rendered in Luisa. The following graphic
                     shows the data-bindings for the contact form.
                `
            },
            {
                "type": "Playground",
                "mode": "contactForm",
                "play": false,
                "labels": {     
                    "databinding": true
                }
            },  
            {
                "type": "Headline",
                "content": `Show Array Data`,
                'id': 'low_code_data_binding.example'
            },
            {
                "type": "Paragraph",
                "content": `
                    Often you have to deal with dynamic array data. For example, you have a list of todos, and you 
                    want to show them the UI. The issue is that you do not know how many elements are there, so you 
                    cannot design for three or five elements upfront. 
                `
            },
            {
                "type": "Paragraph",
                "content": `
                    Luisa has for such a situation the <b>"Repeater"</b> element. You need to
                    select that element that should render the array and set it as a <b>"Smart Container"</b>.
                `
            },
            {
                "type": "Image",
                "size": 'xl',
                "src": 'Plugin_Type_Repeater.png',
                "description": `
                    <ol>
                        <li>
                            Create a frame, and place the element's you want to repeat inside.
                        </li>
                        <li>
                            Select the <b>Smart Container</b> and choose <b>Repeat Children</b> 
                        </li>
                        <li>
                            For each item in the data array, the elements in side the repeater will be copied. 
                        </li>                        
                    </ol>
                `,
                "content": ``
            },
            {
                "type": "Paragraph",
                "content": `
                    The repeater works very similarly to the "v-for" directive in VUE. All child elements will be copied.
                    If you have an array with three items, Luisa will 
                    create three copies of the elements inside the repeater element. 
                    If you have five items, five copies will be created.
                `
            },
            {
                "type": "Playground",
                "mode": "repeater",
                "play": true,
                "labels": {     
                    "repeater": true,
                    "databinding": true
                }
            },  
            {
                "type": "Paragraph",
                "content": `
                    In the example above, you can see that the repeater has one child (the white dotted line). The child
                    has three child elements of its own. When we bind an array to the repeater, the child will
                    be copied and all the nested elements as well.
                `
            },
            {
                "type": "Paragraph",
                "content": `
                    The repeater is different than the other input elements in Luisa. It has two data bindings, instead of one.
                    The <b>input data-binding</b> defines which array to render, and the 
                    <b>output data-binding</b> defines where to store items that the user clicks on.
                `
            },
            {
                "type": "Image",
                "size": 'xl',
                "src": 'Plugin_Options_Repeater.png',
                "description": `
                    <ol>
                        <li>
                            The repeater element has two data bindings.
                        </li>
                        <li>
                           The <b>Input</b> defines from which list of data the elements should be read.
                        </li>
                        <li>
                            If the user clicks on an list item, the clicked item will be stored varibale defined in <b>Output</b>.
                        </li>
                    </ol>
                `,
                "content": ``
            },
            {
                "type": "Paragraph",
                "content": `
                    You can use data binding on the child elements, for instance, to show item data. Suppose you want to show the list of your friends
                    and you have the following data.
                `
            },
            {
                "type" : "CodePreview",
                "language": "vue",
                "caption": "The list of friend is an array of objects.",
                "content": `
<template>
    <Luisa :design="design" v-model="viewModel"/>
</template>

<script>
import app from './app.json'
export default {
    name: 'Home',
    data: function() {
        return {
            design: app
            viewModel: {
                friends: [
                    {name: 'Klausen', lastname: 'Pastore', age: 23},
                    {name: 'Tasha', lastname: 'Lichtenfels', age:29},
                    {name: 'Mestre', lastname: 'Bimba', age:80},
                ],
                selected: null
            }
        }
    }
}
</script>
                `
            },
            {
                "type": "Paragraph",
                "content": `
                    We would, in this case,  create a <b>input data-binding</b> for the repeater that points to the friends list, 
                    e.g. "<span class="LuisaLowlight">friends</span>". We place a single child (white dotted line) inside the repeater,
                    and add two elements that should show the name and lastname. They are bound to 
                    the "<span class="LuisaLowlight">name</span>" and "<span class="LuisaLowlight">lastname</span>". Please note,
                    that the <b>binding in a repeater is relative</b>, therefore the "friends" prefix is optional.
                `
            },
            {
                "type": "Playground",
                "mode": "repeater2",
                "play": true,
                "labels": {     
                    "repeater": true,
                    "databinding": true
                }
            },  
            {
                "type": "Paragraph",
                "content": `
                   When the user clicks on one of the repeated elements, Luisa will write the clicked item to the variable that is defined
                   in the <b>output data-binding</b>, in the example above for instance into "<span class="LuisaLowlight">selected</span>".
                `
            },
            {
                "type": "Playground",
                "mode": "repeater3",
                "play": true,
                "labels": {     
                    "repeater": true,
                    "databinding": true,
                    "selected": true
                }
            },  
            {
                "type": "Paragraph",
                "content": `
                   This mechanism is useful, if you want to move to another page, and show the data
                   that the user has selected. In the example above, one could for instance show the full details of 
                   the friend on the "details" page. 
                `
            },
        ]
    }
]