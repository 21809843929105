export let texts = [
    {
        "id": "deploy_figma_website",
        "name":"Deploy to Production",
        "css": "",
        "elements": [
            {
                "type": "Paragraph",
                "content": `
                    When you want to deploy your Luisa project into production, 
                    or you want o improve your developer experience by shorting load times, 
                    you should download all artifcats from Figma. This will reduce the duration of reloads and make
                    your project much faster. In addition, downloading will also prevent that changes in the 
                    Figma file, will go live without further review. If there is a design 
                    change, you need to simply download the Figma file again.
                `
            },
            {
                "type": "Paragraph",
                "content": `
                    Luisa comes with small command-line util, which makes downloading the Figma files very easy.
                `
            },
            {
                "type" : "CodePreview",
                "language": "bash",
                "caption": "The luisa-util package allows to download figma files.",
                "content": "npm install luisa-util"
            },
            {
                "type": "Paragraph",
                "content": `
                  Once luisa-util is installed, you call the util on the command line:
                `
            },
            {
                "type" : "CodePreview",
                "language": "bash",
                "caption": "Download Figma files",
                "content": "node node_modules/luisa-util/lib/index.js"
            },
            {
                "type": "Paragraph",
                "content": `
                The command-line interface will ask you for your Figma access token and the Figma file id. 
                In addition, it will ask where to store the images and design file. You can choose 
                the default values by pressing enter.
                If you cloned the template project, there is also a NPM task defined.
                `
            },
            {
                "type": "Paragraph",
                "content": `
                    If you cloned the template project, there is also a NPM task defined.
                `
            },
            {
                "type" : "CodePreview",
                "language": "vue",
                "content": `npm run download`
            },
        ]
    }
]