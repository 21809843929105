export let texts = [
    {
        "id": "low_code_concepts",
        "name": "Concepts",
        "elements": [
            {
                "type": "Paragraph",
                "content": `

                    The Luisa framework adopts the Low-Code idea to front-end development. 
                    The idea of <b>Low-Code</b> is that certain aspects of an application can be easier 
                    described using visual tools. The model is interpreted during runtime by a 
                    dedicated software component. A good example of such an aspect is the user 
                    interface. It is anyhow designed in a graphical tool such as Figma, Sketch, or Quant-UX, 
                    so why translate it manually to code? 
                    <br>
                    Low-Code does not mean, that your entire application needs to be 
                    visually modeled. Plain code is still a better choice for a lot of aspects.
                `
            },
            {
                "type": "Headline",
                "content": `Single Source of Truth`,
                'id': 'low_code_concepts.single_source_of_truth'
            },
            {
                "type": "Paragraph",
                "content": `                            
                    Luisa ensures that the design stays the <b>single source of truth</b>. Luisa takes the design and renders it 
                    on the fly in the browser. All HTML and CSS are generated behind the scenes. Your business 
                    logic and data are implemented in JavaScript and wired through data- and method-binding 
                    to the design. Asa result, the user interface changes automatically when the design changes, but your code
                    keeps working.
                `
            },
            {
                "type": "Image",
                "src": 'LuisaPrinciple.png',
                "content": `Luisa renders the Figma design on the fly`
            },
            {
                "type": "Headline",
                "content": `Data Binding`,
                'id': 'low_code_concepts.data_binding'
            },
            {
                "type": "Paragraph",
                "content": `

                    All your data is passed to the Luisa component as a <b>viewModel</b>, a plain old JavaScript object. 
                    To make this work, you need to let Luisa know which data from the viewModel 
                    to show and which data to write back, e.g. from an input element. For instance, 
                    user input entered the "name" field should be stored in the "user.email" property, 
                    while the "email" field should write to "<span class="LuisaLowlight">user.email</span>".
                    
                    The wiring between the UI and the viewModel is called <b>data-binding</b>, and it is 
                    specified in Figma with the help of the 
                    <a href="https://www.figma.com/community/plugin/858477504263032980/Figma-Low-Code" target="_figma">Figma-Low-Code Plugin</a>. The details 
                    will be explained in the <a href="/help/figma_data_binding.html" class="LuisaHelpLink">Data Binding</a> section.
                `
            },
            {
                "type": "Headline",
                "content": `Business Logic`,
                'id': 'low_code_concepts.method_binding'
            },
            {
                "type": "Paragraph",
                "content": `
                    Your business logic is implemented in JavaScript as methods in the component, which wraps the <Luisa>
                    component. Similar to the viewModel, you need to wire your design elements to the methods. For instance, if the user clicks
                    on a "Submit" button, the viewModel should be sent to your server via the "<span class="LuisaGreenLight">submitUser</span>" method. This wiring is called <b> method binding</b>. 
                    Is also defined in the Figma plugin. The details 
                    will be explained in the <a href="/help/figma_method_binding.html" class="LuisaHelpLink">Business Logic</a> section.
                `
            },
            {
                "type": "Headline",
                "content": `Extensibility`,
                'id': 'luisa_principle.extensions'
            },
            {
                "type": "Paragraph",
                "content": `
                Luisa provides several extensions points that allow you to customize the rendering. First, you can easily overwrite CSS rules, for instance, to add hover effects or other behavior. Moreover, you can also hook in your own components. In the Figma plugin, you can specify that certain elements should be replaced with your custom component. This mechanism gives you full flexibility. For instance, this help page is a combination of Luisa and a custom component.
                        `
            },
            {
                "type": "Image",
                "src": 'LuisaExtensibility.png',
                "content": `Luisa renders the Figma design on the fly`
            },
            {
                "type": "Headline",
                "content": `When to use`,
                'id': 'low_code_concepts.when_tu_use'
            },
            {
                "type": "Paragraph",
                "content": `


                    Luisa is a perfect solution for early projects, such as <b>prototypes or MVPs</b>, where the design still changes 
                    very often. These changes would usually become a burden for the development teams, as the need to rewrite 
                    large parts of the code base with every change in the design. Luisa decouples the design and 
                    development teams, as all design changes are instantaneously updated in the project, without overwriting any code.
                    When you design a landing page, form-driven applications or backend UIs, Luisa is a great choice to 
                    speed up your development.
                    For super interactive applications, that have very complex interactions patterns and a lot 
                    of dynamic elements, Luisa can still be useful, as it allows the integration of a <a href="/help/custom.html" class="LuisaHelpLink">custom</a>  VUE 
                    components. However, there are limits. When you want to build 
                    the next Figma, Luisa is not the best choice.


                `
            }
        ]


    }
]