export let texts = [
    {
        "id": "responsive_figma_layouts",
        "name":"Responsive Layouts",
        "elements": [
            {
                "type": "Paragraph",
                "content": `
                    Luisa tries it's best to create a responsive web site, when rendering Figma designs. However, this task is not always easy, because
                    Figma's data model is different from the HTML model. In HTML elements are nested. Figma, however, 
                    operates with a mixture of absolute positioned elements and elements that are nested in containers like frames. Luisa has
                    a smart algorithm to convert Figma to HTML, but sometimes the results might be different from what you expect.  
                    To get the best results, you need to understand the conventions that Luisa uses. 
                `
            }, 
            {
                "type": "Headline",
                "content": `Auto Nesting`,
                'id': 'responsive_figma_layouts.nesting'
            },  
            {
                "type": "Paragraph",
                "content": `
                    Luisa will convert the absolute positioned elements into a nested representation. 
                    Luisa treats frames, groups, and also rectangles as container elements. For instance, 
                    if you create a button that consists of a rectangle with a label above, Luisa will 
                    place the label inside the button. This behavior is slightly different from Figma, 
                    but allows for better responsive behavior.
                    <br>
                    Figma's auto-layouts make the situation easier for Luisa, as they can be directly 
                    converted to HTML Flex layouts. So, for optimal results, auto-layouts are a great option.  
                    `
            }, 
            {
                "type": "Headline",
                "content": `Flex & grid`,
                'id': 'responsive_figma_layouts.frames'
            },  
            {
                "type": "Paragraph",
                "content": `
                    In each of the containers, Luisa figures automatically out, if it should use CSS Grid or Flex layouts. For all 
                    elements are aligned from top to down, a FLEX layout is used, in other cases, Luisa will use a GRID layout.                                

                `
            },
            {
                "type": "Playground",
                "mode": "grid",
                "labels": {
                    "grid": true,
                    "flex": true
                }
            },     
            {
                "type": "Paragraph",
                "content": `      
                    In the left blue box, the elements A,B and C are placed vertically. Luisa will in 
                    such a case create a FlexBox column layout. In the red box A, B, and C and 
                    again placed vertically, but the D element is placed on the right side. 
                    Luisa will turn such a layout into a Grid layout.
                `
            }, 
            {
                "type": "Headline",
                "content": `Absolute Positions`,
                'id': 'responsive_figma_layouts.absolute'
            },
            {
                "type": "Paragraph",
                "content": `
                    Luisa assumes by <b> default that the design is absolutely positioned</b>. This means the 
                    width and height are fixed, and the elements are pinned
                    to the top and left. This means when the width changes, the elements will stay aligned to  
                `
            }, 
            {
                "type": "Playground",
                "mode": "absolute",
                "play": true,
                "amimation": "grow",
                "labels": {
                    "absolute": true
                }
            },
            {
                "type": "Headline",
                "content": `Centered Positions`,
                'id': 'responsive_figma_layouts.centered'
            },  
            {
                "type": "Paragraph",
                "content": `
                    An exception to this rule is <b>centered elements</b>. If an element is centered in a 
                    containing element, the element will also be centered in the web, even when the 
                    width changes. This is for example useful when you want a 
                    central content pane on a landing page.
                `
            },     
            {
                "type": "Playground",
                "mode": "centered",
                "play": true,
                "amimation": "grow",
                "labels": {
                    "centered": true
                }
            },            
            {
                "type": "Headline",
                "content": `Pinned Positions`,
                'id': 'responsive_figma_layouts.pinned'
            }, 
            {
                "type": "Paragraph",
                "content": `
                    If an element is <b>pinned to the left and right</b> sides, it becomes responsive. Luisa will ensure,
                    that the distance on the left and right to the containing element will stay the same.
                `
            },
            {
                "type": "Playground",
                "mode": "pinned",
                "play": true,
                "amimation": "grow",
                "labels": {
                    "pinned": true
                }
            }, 
            {
                "type": "Headline",
                "content": `Min & Max Width`,
                'id': 'responsive_figma_layouts.centered'
            },  
            {
                "type": "Paragraph",
                "content": `
                    In the Figma plugin, you can also define a min and max-width for elements. 
                    This might, for instance, be useful if you want to create a <b>fluid</b> container for your content.
                `
            },     
            {
                "type": "Playground",
                "mode": "minmax",
                "play": true,
                "amimation": "grow",
                "labels": {
                    "minmax": true
                }
            },    
            {
                "type": "Paragraph",
                "content": `
                    You can see, that the (centered) content starts to grow with the container element, but stops at a certain point.
                `
            }, 
            {
                "type": "Image",
                "size": 'xl',
                "src": 'Plugin_Styles.png',
                "description": `
                    <ol>                               
                        <li>
                            When you select an element and select the <b>Options</b> tab, you can set the min and max-width.
                            
                        </li>  
                        <li>
                            You can also set the element to be <b>wrapped</b>. This means that, if the space 
                            for the children gets too small, new lines are created.
                        </li>                     
                    </ol>
                `,
                "content": ``
            },   
            {
                "type": "Headline",
                "content": `Wrapped`,
                'id': 'responsive_figma_layouts.centered'
            },  
            {
                "type": "Paragraph",
                "content": `
                    When you select the <b>wrapped</b> option in the plugin, Luisa will apply a Flex 
                    wrapped layout. This means the child elements might end up 
                    in a new line. This can be useful when using a long list of items.`
            },     
            {
                "type": "Playground",
                "mode": "wrapped",
                "play": true,
                "amimation": "grow",
                "labels": {
                    "wrapped": true,
                    "absolute": true
                }
            },    
            

            {
                "type": "Headline",
                "content": `Auto-Layout`,
                'id': 'responsive_figma_layouts.auto'
            }, 
            {
                "type": "Paragraph",
                "content": `
                    Luisa can translate Figma's auto-layout to Flexbox layouts. 
                    The spacing is translated to the CSS <b>gap</b> property, paddings to <b>paddings</b>. 
                    Top to down layouts is translated to column layouts. With regards to the responsive behavior, 
                    the left to right alignment is more interesting. In this case, Luisa will apply a 
                    Flex-Row layout. For the elements within an auto-layout the  <b>fixed, hug and fill</b> options are respected.
                `
            }, 
            {
                "type": "Playground",
                "mode": "auto",
                "play": true,
                "amimation": "grow",
                "labels": {
                    "auto": true,         
                    "fixed": true,
                    "fill": true
                }
            },   
            {
                "type": "Paragraph",
                "content": `
                    In the example above the auto-layout has a padding of 20px and a spacing of 20 px as well. In addition, the 
                    containers are pinned to the left and right sides. Therefore the will
                    resize. The fixed child element will maintain its width, while the fill child will expand and occupy the 
                    rest of the space. 
                `
            },  
            {
                "type": "Headline",
                "content": `Fixed Scroll`,
                'id': 'responsive_figma_layouts.fixed_scroll'
            },
            {
                "type": "Paragraph",
                "content": `
                    Elements that are marked in Figma as "fixed position when scrolling", will be placed as CSS fixed elements in Luisa. This
                    means they will maintain their position when scrolling. You can combine this with pinning, e.g. to attach the elements
                    to the bottom or top, left or right of the screen.
                `
            },  
            {
                "type": "Playground",
                "mode": "fixedScroll",
                "play": true,
                "amimation": "grow",
                "labels": {     
                    "fixedScroll": true,
                    "centered":true
                }
            },  
            {
                "type": "Headline",
                "content": `Viewports`,
                'id': 'responsive_figma_layouts.viewports'
            },
            {
                "type": "Paragraph",
                "content": `
                    As you saw, Luisa allows to build responsive layouts, but sometimes the mobile version of a 
                    website is very different from the desktop version. For such scenarios, Luisa allows 
                    you to select different pages of your design for different viewports. For instance, 
                    you can design the mobile version of your design in a Figma page called "MobileLayout", 
                    while the desktop version is designed in a page called "DesktopLayout". 
                    When setting up the Luisa component, you can pass the page names as config parameters. 
                    Luisa will select the current page depending on the screen size of the device.
                `
            }, 
            {
                "type" : "CodePreview",
                "language": "vue",
                "caption": "Different Figma pages are configured for the view ports.",
                "content": `
<template>
    <Luisa :design="design" :config="config" v-model="viewModel"/>
</template>

<script>
import app from './app.json'
export default {
    name: 'Home',
    data: function() {
        return {
            design: app
            viewModel: {
            },
            config: {
                responsive: [
                    { page: "DesktopLayout", types: ["desktop", "tablet"] },
                    { page: "MobileLayout", types: ["mobile"] },
                ]
            }
        }
    }
}
</script>
                `
            },
            {
                "type": "Paragraph",
                "content": `
                    In the example, you can see, that we have specified, that the "DektopLayout" page will be shown on desktop and tablets,
                    while the "MobileLayout" is only shown for mobile devices.
                `
            }, 
        ]
    }
]