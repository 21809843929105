export let texts = [
    {
        "id": "luisa_add_existing",
        "name": "Add to existing project",
        "elements": [          
            {
                "type": "Paragraph",
                "content": `
                            If you want to include the Luisa into an existing project, you need to first include the
                            dependency in you project.
                        `
            },
            {
                "type" : "CodePreview",
                "language": "bash",
                "tabs": [                
                    {
                        "vue": 3,
                        "label": "Vue3",
                        "language": "bash",
                        "content": `npm install luisa-vue@3`
                    },
                    {
                        "vue": 2,
                        "label": "Vue2",
                        "language": "bash",
                        "content": `npm install luisa-vue@2`
                    }
                ]
            },
            {
                "type": "Paragraph",
                "content": `
                            Now you must register Luisa to your VUE application. Usually this happends in the <b>src/main.js</b> file.
                        `
            },
            {
                "type" : "CodePreview",
                "language": "JavaScript",
                "tabs": [
                    {
                        "vue": 3,
                        "label": "Vue3",
                        "content": `
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import luisa from 'luisa-vue'

const app = createApp(App)
app.use(router).mount('#app')
app.use(luisa)      
`
                    },
                    {
                        "vue": 2,
                        "label": "Vue2",
                        "content": `  
import Vue from "vue";
import luisa from 'luisa-vue'
Vue.use(luisa);          
`
                    }
                ]
            },
            {
                "type": "Paragraph",
                "content": `
                            VUE knows now the <Luisa> component, and you can use it in other components. Let's suppose you want to include
                            Luisa in your <b>src/Home.vue</b> file. You need to simply include the <Luisa> tag. 
                        `
            },       
            {
                "type" : "CodePreview",
                "language": "vue",
                "caption": "Use the Luisa component in a parent component.",
                "content": `
<template>
    <Luisa :design="design" :config="config" v-model="viewModel"/>
</template>

<script>
import app from './app.json'
export default {
    name: 'Home',
    data: function() {
        return {
            design: app
            viewModel: {
            },
            config: {
            }
        }
    }
}
</script>
                `
            },     
            {
                "type": "Paragraph",
                "content": `
                    As you can see, the <Luisa> element has three properties that you need to pass:                           
                `
            },      
            {
                "type": "Paragraph",
                "content": `                                          
                    <ol>
                        <li>
                            The <b>design</b> property must point to the visual design that you 
                            have created with Figma or Quant-UX. There are different ways to pass
                            the design, which will be explained a little bit later.
                        </li>  
                        <li>
                            The <b>v-model</b> property is used to exchange data between Luisa and your application. 
                            All data that you want to pass, must be a child of the object you pass here. 
                            In the following sections, we will call this data object the viewModel. 
                            Please note, the data binding is two-way. All data that is entered 
                            in Luisa is propagated to the <b>viewModel</b>.
                        </li>  
                        <li>
                            The <b>config</b> property allows configuring the behavior, 
                            for instance, to define different designs for different screen sizes.
                        </li>  
                    </ol>
                `
            },      
            {
                "type": "Paragraph",
                "content": `
                    To make all links work, you need also to configure the router. Usually,
                    this happens in the <b>src/router/index.js</b> file. In the example below, we 
                    link all routes to the Home.vue page which wraps the <Luisa> component. 
                    Luisa listens to router events and changes the page accordingly.           
                `
            },
            {
                "type" : "CodePreview",
                "language": "vue",
                "content": `
{
    path: '/',
    name: 'Home',
    component: Home
},
{
    path: '/:screenName.html',
    name: 'Home2',
    component: Home
}
                `
            }    
        ]


    }
]