export let texts = [
    {
        "id": "figma_method_binding",
        "name":"Business Logic",
        "elements": [
             
            {
                "type": "Paragraph",
                "content": `
                   The Luisa component is contained in a VUE component, in our example, the Home.vue component. This component will be called
                   the <b>parent</b> in the following sections. Luisa allows
                   you to call methods in the parent. This is where you can implement your business logic.
                `
            },
            {
                "type": "Image",
                "src": 'MethodBinding.png',
                "content": `Luisa can call methods in the parent`
            },
            {
                "type": "Paragraph",
                "content": `
                   The business logic might contain calls to backend microservices, calculations but also redirect Luisa to other screens.
                   The different functions of your business logic are implemented in the <b>methods</b> section of 
                   the parent component. You can use plain JavaScript and use
                   all mechanisms provided by VUE.
                `
            },
            {
                "type" : "CodePreview",
                "language": "vue",
                "caption": "The business logic is implemented as methods.",
                "content": `
<template>
    <Luisa :design="design" :config="config" v-model="viewModel"/>
</template>

<script>
import app from './app.json'
export default {
    name: 'Home',
    data: function() {
        return {
            design: app
            viewModel: {
            },
            config: {
            }
        }
    },
    methods: {
        sendContact (e) {

        }
    }
}
</script>
                `
            },
            {
                "type": "Headline",
                "content": `Method Binding`,
                'id': 'figma_method_binding.binding'
            },
            {
                "type": "Paragraph",
                "content": `
                   In the code sample above there is a "<span class="LuisaGreenLight">sendContact</span>" method, but how does Luisa know when to
                   execute it? Just like for the data-binding, you need to connect a Figma element 
                   to the method that contains the business logic. We call this mechanism
                   <b>method-binding</b>. When the user clicks on an element, e.g. a button, the method is called. 
                   You can define the method-binding in the plugin.
                `
            },
            {
                "type": "Image",
                "size": 'xl',
                "src": 'Plugin_Options.png',
                "description": `
                    <ol>
                        <li>
                            Launch the Figma-Low-Code plugin and select the desired element.
                        </li>  
                        <li>
                            Change to the <b>Options</b> tab.
                        </li> 
                        <li>
                            Enter the name of the method you want to call, e.g. "<span class="LuisaGreenLight">sendContact</span>"
                        </li>
                        <li>
                            When the user clicks on the element, the method is called.
                        </li>
                    </ol>               
                `,
                "content": `Method binding`
            },     
            {
                "type": "Headline",
                "content": `Example`,
                'id': 'figma_method_binding.example'
            },
            {
                "type": "Paragraph",
                "content": `
                    Let's continue with the contact form we have introduced in the data-binding section.
                    Remember, we had created a viewModel that would hold a "contact" object and defined 
                    the data-bindings for the email, message, and status properties of 
                    the contact object. Let's now implement the following business logic:
                `
            },
            {
                "type": "Paragraph",
                "content": `                
                   <ol>
                        <li>
                            Once the user has entered her mail and the message, she can press "Send"
                        </li>  
                        <li>
                            We will check if she has entered an email, if not, we will show a hin in the status field next to the button.
                        </li> 
                        <li>
                            If she has entered an email, we will send the concat form to our server, and update the status to notify her that
                            the contact was sent.
                        </li>                       
                    </ol>              
                `
            },
            {
                "type": "Paragraph",
                "content": `
                    To do so, we need to wire the "Send" button to the "<span class="LuisaGreenLight">sendContact</span>" method 
                    using the Figma-Low-Code plugin. The data and method-binding of the contact page should look like this:                    
                    
                `
            },
            {
                "type": "Playground",
                "mode": "contactForm",
                "play": false,
                "labels": {     
                    "databinding": true,
                    "methodbinding": true
                }
            }, 
            {
                "type": "Paragraph",
                "content": `
                    <br>
                    Now we would have to implement the business logic in the  "<span class="LuisaGreenLight">sendContact</span>". 
                    Because Luisa syncs the viewModel with the parent component, we can read the latest user input directly from
                    "<i>this.viewModel</i>". We also use the viewModel to update the status message.
                    
                `
            },
            {
                "type" : "CodePreview",
                "language": "vue",
                "caption": "The sendContact method implements the busines logic.",
                "content": `
<template>
    <Luisa :design="design" :config="config" v-model="viewModel"/>
</template>

<script>
import app from './app.json'
export default {
    name: 'Home',
    data: function() {
        return {
            design: app
            viewModel: {
                contact: {
                    email: '',
                    status: 'Fill out the form',
                    message: ''
                }
            },
            config: {
            }
        }
    },
    methods: {
        async sendContact (event) {
            if (this.viewModel.email === '') {
                // we update the status propery, 
                // if the email is empty
                this.viewModel.status = 'Please enter your email'
            } else {
                // we send the request to the server
                await fetch('https://yourserver.com', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        email: this.viewModel.email,
                        message: this.viewModel.message
                    })
                })
                // we show a success message, but this time
                // we use the Luisa event to access the viewModel
                event.viewModel.status = 'The form was send'
            }
        }
    }
}
</script>
                `
            },
            {
                "type": "Headline",
                "content": `Method Signature`,
                'id': 'figma_method_binding.types'
            },
            {
                "type": "Paragraph",
                "content": `
                    In the example you can see, that the an <b>event</b> object is passed to the method. The object contains
                    a reference to the viewModel as well, so you also read or write data also through the event. The event 
                    contains the following attributes:                    
                `
            },
            {
                "type": "Paragraph",
                "content": `
                    <ol>
                        <li>
                           <b>viewModel</b>: A reference to the model.
                        </li>  
                        <li>
                            <b>element</b>: The element that triggered the event
                        </li> 
                        <li>
                            <b>event</b>: The javascript event, e.g. click.
                        </li> 
                        <li>
                            <b>luisa</b>: The Luisa instance
                        </li>                       
                    </ol>         
                    
                `
            },
            {
                "type": "Paragraph",
                "content": `
                    If the method returns a string, which matches the name of a screen (root level frame), Luisa will navigate to this screen.            
                `
            },
            {
                "type": "Headline",
                "content": `Event types`,
                'id': 'figma_method_binding.types'
            },
            {
                "type": "Paragraph",
                "content": `
                    Luisa supports different kinds of events, which can trigger the execution of the business logic. For instance and input field
                    has two events. First when the user clicks on it, and second when she changes the value. Screens have an <b>onload</b> event, 
                    which allows to perfect data. The Figma-Low-Code plugin will show you the right options depending on the selected element and type. 
                `
            },
            {
                "type": "Image",
                "size": 'xl',
                "src": 'Plugin_Options_Input.png',
                "description": `
                    <ol>
                        <li>
                           Elements that can recieve user data, e.g. input, password or textarea fields, trigger
                           an <b>change</b> event.
                        </li>          
                        <li>
                            Use this to implment validations or filtering.
                        </li>                    
                    </ol>
                `,
                "content": ``
            },
            {
                "type": "Headline",
                "content": `Programatic Navigation`,
                'id': 'figma_method_binding.types'
            },
            {
                "type": "Paragraph",
                "content": `
                    Sometimes you might want to send the user to different pages, 
                    depending on the data. By default, Luisa supports a very simple 
                    mechanism to support such programmatic navigation. When a business method is 
                    invoked, you can return the name of a screen. Luisa will navigate to the right screen.
                    <br>
                    In addition, you can also use the 
                    <a href="https://router.vuejs.org/guide/essentials/navigation.html">VUE router</a>, to set routes manually.
                `
            },           
            {
                "type" : "CodePreview",
                "language": "vue",
                "caption": "The sendContact method implements the busines logic.",
                "content": `
<template>
    <Luisa :design="design" :config="config" v-model="viewModel"/>
</template>

<script>
import app from './app.json'
export default {
    name: 'Home',
    data: function() {
        return {
            design: app
            viewModel: {               
            },
            config: {
            }
        }
    },
    methods: {
        async businessMethod (event) {
            if (this.viewModel.age < 30) {
                return 'Hobbies_until_30'
            } 
            if (this.viewModel.age >= 30 && this.viewModel.age <50) {
                return 'Hobbies_from_30_to_50'
            } 
            return 'Hobbies_over_50'              
        }
    }
}
</script>
                `
            },
            {
                "type": "Paragraph",
                "content": `
                    The code snippet above shows an example. Suppose you ask the user to enter their age and 
                    want to show them hobbies for their age group (0 - 29, 30 - 49, 50 - 100). You can create for each age group
                    a different screen and use a business method to navigate to the right screen.
                `
            },
        ]
    }
]