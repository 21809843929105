export let texts = [
    {
        "id": "luisa_framework",
        "name": "Getting Started",
        "elements": [          
            {
                "type": "Paragraph",
                "content": `
                    The Luisa framework is an OpenSource project that turns Figma and Quant-UX designs into fully 
                    functional VUE applications. 
                `
            },
            {
                "type": "Video",
                "video": `https://www.youtube.com/embed/moiu6mQX7rg`,
                "content": 'Hello World Tutorial'
            },

            {
                "type": "Headline",
                "h": "3",
                "content": `Design`,
                'id': 'luisa_framework.design_in_figma'
            },
            {
                "type": "Paragraph",
                "content": `
                Let's start with a super simple Hello World example. The idea is very simple. 
                A user can enter her name, and when she presses on a button, she is greeted with her name. 
                Before you can start using Luisa, you need to create the design for the app. 
                The bare minimum is shown below. There is an input element, a button, and a text 
                element for the output. The input element consists out of an auto-layout 
                frame with an embedded text element.
                `
            },
            {
                "type": "Image",
                "size": 'xs',
                "src": 'HelloWorld_Example.png',
       
                "content": `A simple Hello World application`
            },
            {
                "type": "Headline",
                "h": "3",
                "content": `Project Setup`,
                'id': 'luisa_framework.project_setup'
            },
            {
                "type": "Paragraph",
                "content": `
                    Once the design is ready, you need to setup a VUE project. The best way to start is by cloning our
                    template repository for a fresh start. It includes everything you need and is fully configured.
                    If you want to add Luisa to an existing project, read the 
                    <a href="/help/luisa_add_existing.html" class="LuisaHelpLink">Add to existing project</a> chapter for more details.
                `
            },
            {
                "type" : "CodePreview",
                "language": "bash",
                "tabs": [                   
                    {
                        "vue": 3,
                        "label": "Vue3",
                        "language": "bash",
                        "content": `git clone https://github.com/KlausSchaefers/luisa-vue3-starter.git`
                    },
                    {
                        "vue": 2,
                        "label": "Vue2",
                        "language": "bash",
                        "content": `git clone https://github.com/KlausSchaefers/luisa-vue2-starter.git`
                    }
                ]
            },
            {
                "type": "Paragraph",
                "content": `
                    <p class="LuisaHelpHint">
                        <b>Hint:</b> You need a working VUE development setup. Please make sure you have 
                        <a href="https://nodejs.org/en/" target="_node" >Node.js</a> and the 
                        <a href="https://cli.vuejs.org/" target="_node">VUE-CLI</a> installed. 
                    </p>
                `
            },
            {
                "type": "Headline",
                "h": "3",
                "content": `Linking Figma`,
                'id': 'luisa_framework.design_in_figma'
            },
            {
                "type": "Paragraph",
                "content": `
                    Once you have setup Luisa, it is time to include your design. There are two ways to do this.
                `
            },
            {
                "type": "Paragraph",
                "content": `      
                    <ol>
                        <li>
                            The simplest way is to <b>link</b> Luisa to the "live" version at Figma. Linking the project has the advantage that the latest design 
                            is loaded on a page reload. When you change something in Figma,
                            simply reload the page and the design is updated.
                        </li>          
                        <li>
                            As an alternative, you can <b>download</b> the Figma file to 
                            your project and download all images and vector graphics. The page will
                            load much faster, however, you have to re-download everything, once the design changes. 
                            You can more information in the <a href="/help/deploy_figma_website.html" class="LuisaHelpLink">Deploy to Production</a>
                        </li>                                                  
                    </ol>
                `
            },
            {
                "type": "Paragraph",
                "content": `
                    To link Luisa to Figma, you need to pass the <i>figmaFile</i> ID and the <i>figmaAccessKey</i>, sometimes also called the Figma access token.
                    The figmaFile ID is part of the URL shown in the browser. So if the URL is
                    <i>https://www.figma.com/file/<span class="LuisaHighlight">abcdefghijkl</span>/My-project</i>
                    , the file id is "abcdefghijkl". You can get the figmaAccessKey in your Figma settings 
                    (<a href="https://www.figma.com/developers/api#access-tokens">Details</a>)
                `
            },
            {
                "type": "Paragraph",
                "content": `
                    Open the <i>src/views/Home.vue</i> file and pass the link as the design object.
                `
            },
            {
                "type" : "CodePreview",
                "language": "vue",
                "caption": "The Home.vue file is using Luisa and linking to Figma.",
                "content": `
<template>
    <Luisa :design="design" v-model="viewModel"/>
</template>

<script>
export default {
    name: 'Home',
    data: function() {
        return {
            design: {
                figmaFile: "abcdefghijkl",
                figmaAccessKey: "my-secret-figma-key",
            }
            viewModel: {
            }
        }
    }
}
</script>
                `
            },    
            {
                "type": "Paragraph",
                "content": `
                    Now you can start the server and check you results at <a href="http://localhost:8080" target="_luisa">http://localhost:8080</a>.
                    You start the server also from the command line, as any other VUE project.
                `
            },
            {
                "type" : "CodePreview",
                "language": "vue",
                "content": `npm run serve`
            },
           
            {
                "type": "Headline",
                "h": "3",
                "content": `Using the Plugin`,
                'id': 'luisa_framework.plugin'
            },
            {
                "type": "Paragraph",
                "content": `
                    If you try out the app in the browser, you will notice that there is no interactivity. The input element
                    is just a DIV and thus you cannot enter data in the text field, nor is there any reaction when you press the button.
                    <br>
                    To change this, you need to use the
                    <a href="https://www.figma.com/community/plugin/858477504263032980/Figma-Low-Code">Figma-Low-Code</a>
                    plugin and add metadata to the design, so that Luisa knows how to render the elements, for instance, 
                    to create a real HTML input element.
                `
            },
            {
                "type": "Paragraph",
                "content": `
                    Once you have installed the Figma plugin, you need to run the plugin (Right Mouse Click &gt; Plugins &gt; Figma Low Code). 
                    The plugin will allow you to set the required metadata. For our Hello World example, perform the following steps:
                `
            },
            {
                "type": "Paragraph",
                "content": `
                    1) Select the element that you want to turn into an input element. In our case it is the text element that 
                    is placed inside the frame. The plugin UI will update. You can now select the correct input type, in this
                    case <b>Input Field</b>.
                `
            },
            {
                "type": "Image",
                "size": "fw",
                "src": 'HelloWorld_Plugin_Type.png',
                "content": `Setting the element type.`
            },
            {
                "type": "Paragraph",
                "content": `
                    2) Change the tab to <b>Options</b> and enter the "<span class="LuisaLowlight">name</span>" into the
                    <b>Data Binding</b> field. As a result, Luisa will save all text that the users type, into
                    a variable called "name".
                `
            },
            {
                "type": "Image",
                "size": "fw",
                "src": 'HelloWorld_Plugin_Data.png',
                "content": `Define the data binding for the input.`
            },
            {
                "type": "Paragraph",
                "content": `
                    3) Select the text element which should show the output message. Set the
                    <b>Data Binding</b> field to  "<span class="LuisaLowlight">result</span>".
                    During runtime, Luisa will replace the text in the design with the value that is stored in the 
                    "result" variable.
                `
            },
            {
                "type": "Image",
                "size": "fw",
                "src": 'HelloWorld_Plugin_Result.png',
                "content": `Define the data binding for the output`
            },
            {
                "type": "Paragraph",
                "content": `
                    4) Select the button element, and enter "<span class="LuisaGreenLight">sayHello</span>" into the 
                    <b>Method Binding</b> field. When the user clicks on the button, Luisa will try to find a method
                    called "sayHello" and execute it. 
                `
            },
            {
                "type": "Image",
                "src": 'HelloWorld_Plugin_Method.png',
                "size": "fw",
                "content": `Define the method to call for button clicks.`
            },
            {
                "type": "Headline",
                "h": "3",
                "content": `Business Logic`,
                'id': 'luisa_framework.edit_home'
            },
            {
                "type": "Paragraph",
                "content": `
                    When you refresh the browser, you can see that that there is now an input field, and
                    that you can enter data into it.
                `
            },
            {
                "type": "Paragraph",
                "content": `
                
                    The final step is to process the data and turn it into the "Hello {name}" message. 
                    To do so, you need access to Luisa's data. This happens through the so-called <b>viewModel</b>. 
                    Just add two string variables called "name" and "result" to the viewModel that is 
                    defined in the Home.vue file. Also, you need to create a method called "sayHello" which will 
                    concatenate the name variable with the word "Hello" and store the result in the result variable.
                    
                `
            },
            {
                "type" : "CodePreview",
                "language": "vue",
                "caption": "Updating the viewModel and added the sayHello() method.",
                "content": `
<template>
    <Luisa :design="design" v-model="viewModel"/>
</template>

<script>
export default {
    name: 'Home',
    data: function() {
        return {
            design: {
                figmaFile: 'abcdefghijkl',
                figmaAccessKey: 'my-secret-figma-key',
            }
            viewModel: {
                name: '',
                result: ''
            }
        }
    },
    methods: {
        sayHello () {
            this.viewModel.result = 'Hello ' + this.viewModel.name
        }
    }
}
</script>
                `
            },    
            {
                "type": "Paragraph",
                "content": `
                   When you refresh the browser you can enter data and pressing the "Say" button will update
                   the text element next to it.
                `
            },
            {
                "type": "Paragraph",
                "content": `
                   The following section will explain the inner mechanics of the Luisa framework in more detail.
                `
            }
        ]


    }
]