export let texts = [
    {
        "id": "custom",
        "name":"Customize Rendering",     
        "elements": [
            {
                "type": "Paragraph",
                "content": `
                   Luisa was designed to allow front-end developers to customize the rendering and 
                   embed their own UI code when needed. For example,
                   you want to have special input elements, that would be 
                   very hard to model in Figma.
                `
            },
            {
                "type": "Image",                     
                "src": 'Customization.png',
                "content": ``
            },
            {
                "type": "Paragraph",
                "content": `
                   To allow this, Luisa supports custom components. In the Figma-Low-Code plugin, you
                   can define that a given element should be replaced with a custom VUE component 
                   during rendering. The scope of these components is not limited. 
                   You can replace only certain input elements, but also complete sections of a screen.
                `
            },
            {
                "type": "Image",      
                "src": 'Plugin_Custom.png',
                "size": 'xl',
                "description": `
                    <ol>
                        <li>
                            Select the element that should be replaced during runtime with a custom component.
                        </li>          
                        <li>
                            Select as the type <b>Custom Component</b>.
                        </li>
                        <li>
                            Enter the name of your custom component, e.g. "MyCustomComponent".
                        </li>                      
                    </ol>
                `,
                "content": ``
            },
            {
                "type": "Paragraph",
                "content": `
                   To make the custom component visible in Luisa, you need to register it in VUE. In VUE3 this happens 
                   in the "<i>src/main.js</i>" file, in VUE 2 you can
                   do it in the same file where you register Luisa, e.h. "<i>src/views/Home.vue</i>"
                `
            },
            {
                "type" : "CodePreview",
                "language": "JavaScript",
                "caption": "Register custom components in the main.js",
                "tabs": [
                    {
                        "vue": 3,
                        "label": "Vue3",
                        "content": `
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import luisa from 'luisa-vue'
import MyCustomComponent from './components/MyCustomComponent'

const app = createApp(App)
app.use(router).mount('#app')
app.use(luisa)
// register under the same name as in the plugin
app.component('MyCustomComponent', MyCustomComponent)              
`
                    },
                    {
                        "vue": 2,
                        "label": "Vue2",
                        "content": `    
import Vue from "vue";
import luisa from 'luisa-vue'
import MyCustomComponent from './components/MyCustomComponent'

Vue.use(luisa);  
// register under the same name as in the plugin
Vue.component('MyCustomComponent', MyCustomComponent)  
`
                    }
                ]
            },
        ]
    }
]