export let texts = [
    {
        "id": "figma_low_code_plugin",
        "name":"Figma Plugin",
        "elements": [       
            {
                "type": "Paragraph",
                "content": `
                    Figma is a design tool, and not made for application building. You can for instance draw an input
                    box, but it is still a vector. To make Luisa understand that the element is actually an input
                    field, we have to add certain meta information to the element. This is where the 
                    <a href="https://www.figma.com/community/plugin/858477504263032980/Figma-Low-Code">Figma-Low-Code</a>
                    plugin comes into play. It allows you to add this can of meta information to your Figma files. 
                    <br>
                    You can download
                    the plugin
                    <a href="https://www.figma.com/community/plugin/858477504263032980/Figma-Low-Code">here</a>.
                `
            },
            {
                "type": "Headline",
                "content": `User Interface`,
                'id': 'figma_low_code_plugin.ui'
            },
            {
                "type": "Paragraph",
                "content": `                            
                    Once you have started the plugin, you need to select the element that you want to edit. The user
                    interface of the plugin will change accordingly.
                `
            },
            {
                "type": "Headline",
                "content": `Screens`,
                'id': 'figma_low_code_plugin.screens'
            },
            {
                "type": "Paragraph",
                "content": `                            
            For Luisa, all root level frames are so-called screens. They will 
            be the pages in your application. For screens, you can select the following properties.           
                `
            },
            {
                "type": "Image",
                "size": 'xl',
                "src": 'Plugin_Screen.png',
                "description": `
                    <ol>
                        <li>
                            Select the <b>Start Screen</b> checkbox, if you want that the application starts with this screen. If no
                            start screen is selected, the first root level frame will be selected.
                        </li>
                        <li>
                            <b>Overlay</b> screens will be rendered on top of other screens, for instance, to 
                            build a dialog.
                        </li>
                        <li>
                            Define the <b>Method</b> to be called when the screen is loaded. This can be useful to fetch
                            data from a server.
                        </li>
                    </ol>
                `,
                "content": ``
            },
            {
                "type": "Headline",
                "content": `Element Types`,
                'id': 'figma_low_code_plugin.widgets'
            },
            {
                "type": "Paragraph",
                "content": `                            
                            For all other elements, you can define how the element should be rendered, e.g.
                            input fields, text areas, and so on.             
                        `
            },
            {
                "type": "Image",
                "size": 'xl',
                "src": 'Plugin_Type.png',
                "description": `
                    <ol>
                        <li>
                            The plugin works by default for Luisa. If you work with Python, and want to use TKInker,
                            you can select a different target platform. Check out the 
                            <a href="https://github.com/ParthJadhav/Tkinter-Designer">TKInder Designer</a> project for more details
                        </li>          
                        <li>
                            Sometimes you want to handle certain groups of elements as one image, e.g. a logo
                            build of several vectors. In this case select <b>Vector Group</b>
                        </li>
                        <li>
                            If you want to hook in your own VUE components, select <b>Custom</b>, and enter the name
                            of the component in the text field that will appear.
                        </li>
                    </ol>
                `,
                "content": ``
            },
            {
                "type": "Paragraph",
                "content": `                            
                        The following element types are supported:     
                        <ol>    
                            <li>
                                <b>Default:</b> The element will be rendered as a DIV or LABEL element.
                            </li>
                            <li>
                                <b>Input Field:</b> The element will be rendered as a INPUT element. If you
                                select a label, the text will be used a placeholder.
                            </li>
                            <li>
                                <b>Vector Group:</b> The element, group or artbaord will be rendered as an image. Use this
                                for complex graphics to optimize the download speed.                 
                            </li>
                            <li>
                                <b>Dynamic Image:</b> If you want to show images coming from a backend server.               
                            </li>
                            <li>
                                <b>Password Field:</b> The element will be rendered as an
                                INPUT field with its type set to password.               
                            </li>
                            <li>
                                <b>Text Area:</b> The element will be rendered as an
                                TEXT-AREA field for multi line input.              
                            </li>
                            <li>
                                <b>Dropdown:</b> The element will be rendered as an OPTION element. The options
                                are set in the "Options" tab            
                            </li>
                            <li>
                                <b>Smart Container:</b> The Smart Container type allows you to repeat the embedded 
                                elements or toggle them on user interactions. Repeating elements allows 
                                creating dynamic lists, whereas toggling the visibility 
                                of child elements, allows creating checkboxes.
                            </li>
                            <li>
                                <b>Link:</b> The element will be created as a A element. You can set on target URL
                                in the otptions to link to external web sites.          
                            </li>
                            <li>
                                <b>Custom:</b> If you want to hook in your own VUE components, select <b>Custom</b>, and enter the name
                                of the component in the text field that will appear.         
                            </li>
                        </ol>       
                        `
            },
            {
                "type": "Headline",
                "content": `Styles`,
                'id': 'figma_low_code_plugin.styles'
            },
            {
                "type": "Paragraph",
                "content": `                            
                            You can set for each widget hover and focus style. You could also build this, using
                            Figmas interactive components, but using the plugin is simpler.             
                        `
            },
            {
                "type": "Image",
                "size": 'xl',
                "src": 'Plugin_Styles.png',
                "description": `
                    <ol>
                        <li>
                            You can set <b>HOVER</b> styles. This includes the border, background and text color. For 
                            inout elements there is also a <b>FOCUS</b> style defined.
                        </li>          
                        <li>
                            You can set <b>min and max-width</b> for certain elements to configure the responsive behavior. Use
                            these options for instance to make the main contained fluid.
                        </li>
                        <li>
                            Certain elements should change the mouse pointer. Set the <b>Cursor</b> property accordingly.
                        </li>
                        <li>
                            Toggle visibility for different screen sizes with the <b>Breakpoints</b> option.
                        </li>
                    </ol>
                `,
                "content": ``
            },
            {
                "type": "Headline",
                "content": `Options`,
                'id': 'figma_low_code_plugin.options'
            },
            {
                "type": "Paragraph",
                "content": `                            
                            You can configure the data and method binding in the options tab. For advanced 
                            element types, there might be several different data bindings. For example, the 
                            <b>Repeater</b> has and input and output binding. The input binding must hold the list 
                            of elements to repeat, whereas the output binding will contain 
                            the element where the user has clicked on.
                            <br>
                            the details will be explained in the <a href="/help/figma_data_binding.html" class="LuisaHelpLink">Data Binding</a> 
                            and <a href="/help/figma_method_binding.html" class="LuisaHelpLink">Business Logic</a> sections.  
                        `
            },
            {
                "type": "Image",
                "size": 'xl',
                "src": 'Plugin_Options.png',
                "description": `
                    <ol>
                        <li>
                            The data binding defines which <b>data goes in and out</b> of your widget. Simple widgets like and input field,
                            will have only one data binding.
                        </li>          
                        <li>
                            The method binding defines which <b>method of the parent</b> component to execute.
                        </li>
                        <li>
                            For interactive components, each version can have a different value attached. E.g. true and false
                            for a checkbox.
                        </li>
                        <li>
                            For <b>Link</b> elements, you can set here the target URL.
                        </li>
                    </ol>
                `,
                "content": ``
            },
        ]


    }
]