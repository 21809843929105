export let texts = [
    {
        "id": "quant_ux",
        "name":"Quant-UX",
        "css": "MatcHelpTopicSpacer",
        "elements": [
            {
                "type": "Paragraph",
                "content": `
                    Next to Figma, Luisa suports also <a href="https://quant-ux.com/">Quant-UX</a> prototypes. Quant-UX is a free and OpenSource 
                    prototyping tool. It is different from Figma, as it allows to work with real widgets, e.g. 
                    input fields, tables, sliders, and so on. Quant-UX also contains elements to model logic flows and call web services.
                    
                `
            }, 
            {
                "type": "Image",
                "size": 'screen',
                "content": "Quant-UX is an OpenSource prototyping tool.",
                "src": 'Quant_UX_Canvas.png'
            },
            {
                "type": "Headline",
                "content": `When to use`,
                'id': 'quant_ux.when_to_use'
            },       
            {
                "type": "Paragraph",
                "content": `            
                    When you want to build a data-heavy application, that needs a lot of user input, 
                    Quant-UX might be a good choice. It comes with built-in support for more than 
                    30 functional input widgets, support animations, and also allows to 
                    visually model conditional navigation.      
                `
            },   
            {
                "type": "Headline",
                "content": `Getting Started`,
                'id': 'quant_ux.started'
            }, 
            {
                "type": "Paragraph",
                "content": `            
                    The setup for Quant-UX is very similar to the Figma setup. See the 
                    <a href="/help/luisa_framework.html" class="LuisaHelpLink">Geeting Started</a> section. First, 
                    you need to install Luisa via NPM, set up the router, and include the Luisa component.
                `
            }, 
            {
                "type" : "CodePreview",
                "language": "bash",
                "tabs": [                
                    {
                        "vue": 3,
                        "label": "Vue3",
                        "language": "bash",
                        "content": `npm install luisa-vue@3`
                    },
                    {
                        "vue": 2,
                        "label": "Vue2",
                        "language": "bash",
                        "content": `npm install luisa-vue@2`
                    }
                ]
            },
            {
                "type": "Paragraph",
                "content": `
                            If you start from scratch, just clone the GIT template repositories. The include everything you need and exverything is already
                            configured.
                        `
            },
            {
                "type" : "CodePreview",
                "language": "bash",
                "tabs": [                   
                    {
                        "vue": 3,
                        "label": "Vue3",
                        "language": "bash",
                        "content": `git clone https://github.com/KlausSchaefers/luisa-vue3-starter.git`
                    },
                    {
                        "vue": 2,
                        "label": "Vue2",
                        "language": "bash",
                        "content": `git clone https://github.com/KlausSchaefers/luisa-vue2-starter.git`
                    }
                ]
            },
            {
                "type": "Paragraph",
                "content": `            
                    There are some slight differences though. Quant-UX just requires a so called <b>Luisa Token</b>. You can get this, by opening the 
                    <span class="LuisaHighlight"> Menu </span> and clicking on <span class="LuisaHighlight"> Share </span>
                `
            },   
            {
                "type": "Image",
                "size": 'screen',
                "content": "Crate a Luisa token in the Canvas",
                "src": 'Quant_UX_Token.png'
            },
            {
                "type": "Paragraph",
                "content": `            
                    Once you have obtained the Luisa token, you can pass it as the <b>:design</b> parameter to the Luisa component. If you just pass the token,
                    the design will be loaded every time you reload the page. This is the so-called <b>live</b> mode. #
                    You can also download the design as descibed in the 
                    <a href="/help/deploy_figma_website.html" class="LuisaHelpLink">Deployment</a> section. Quant-UX is often
                    faster than the Figma API, so in most cases it is ok for development to work in the live mode. 
                `
            },   
            {
                "type" : "CodePreview",
                "language": "vue",
                "caption": "Pass the Luisa token to have live updates",
                "content": `
<template>
    <Luisa :design="design" :config="config" v-model="viewModel"/>
</template>

<script>
export default {
    name: 'Home',
    data: function() {
        return {
            design: 'YourLuisaToken'
            viewModel: {
            },
            config: {
            }
        }
    }
}
</script>
                        
                `
            },
            {
                "type": "Headline",
                "content": `Layout`,
                'id': 'quant_ux.design'
            }, 
            {
                "type": "Paragraph",
                "content": `            
                    The Quant-UX layout is slightly different to Figmas layout system. By default, all elements have a relative width, so 
                    a prototype will be to some extend responsive by default. You can, however, fix the widget and height to get a similar 
                    layout to Figma.      
                `
            },   
            {
                "type": "Playground",
                "mode": "relative",
                "play": true,
                "amimation": "grow",
                "labels": {
                    "relative": true
                }
            },
            {
                "type": "Paragraph",
                "content": `
                    In the example above you can see that the width, but also the distance to the left side will grow proportionally.     
                    You can however, <b>fix the widget and height</b> to get a similar absolute layout as in Figma. Quant-UX allows
                    also to <b>pin to the parent</b>.
                `
            }, 
            {
                "type": "Playground",
                "mode": "absolute",
                "play": true,
                "amimation": "grow",
                "labels": {
                    "absolute": true
                }
            },
            {
                "type": "Paragraph",
                "content": `
                    Quant-UX allows also to <b>pin to the parent</b>. In the case, the distance to the next elements will be kept constant.
                `
            }, 
            {
                "type": "Playground",
                "mode": "pinned",
                "play": true,
                "amimation": "grow",
                "labels": {
                    "pinned": true
                }
            },
            {
                "type": "Paragraph",
                "content": `            
                    Quant-UX does not come with an auto-layout option, so Luisa will figure out the nesting 
                    and spacing automatically as described in the 
                    <a href="/help/responsive_figma_layouts.html" class="LuisaHelpLink">Responsive Layout</a> section.
                `
            },   
            {
                "type": "Headline",
                "content": `Low Code View`,
                'id': 'quant_ux.lowcode'
            }, 
            {
                "type": "Paragraph",
                "content": `            
                    In Figma, you need to provide the metadata, such as the element type or the data- and 
                    method-binding with the help of the Figma-Low-Code plugin. 
                    Quant-UX has built-in support for these features.               
                `
            },  
            {
                "type": "Paragraph",
                "content": `            
                <ol>
                    <li>
                        You do not have to set the element input type, as Quant-UX comes with a rich selection of widgets. All 
                        widgets are supported in Luisa.
                    </li>       
                    <li>
                        Every element has different <b>states</b>, e.g. hover or focus. You can simply define the styles for these
                        states in the editor.
                    </li>
                    <li>
                        The data and method-binding are defined in a dedicated <b>Low-Code View</b>. This view, will grey out the design and show
                        overlays for the bindings.
                    </li>
                        
                </ol>
                `
            },  
            {
                "type": "Image",
                "size": 'screen',
                "content": "The Quant-UX low code view allows to set data- and method-binding.",
                "src": 'Quant_UX_Low_Code_View.png'
            },
        ]
    }
]